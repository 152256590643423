import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class AppCookiesService {
  private readonly sessionTimeoutCookieName = 'timeout';

  constructor(private cookieService: CookieService) {}

  getSessionTimeoutCookie(): any {
    return this.cookieService.get(this.sessionTimeoutCookieName);
  }
}
