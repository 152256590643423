import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, filter, map, take, takeUntil } from 'rxjs';
import { ClusterService } from 'src/app/core/utils/cluster.service';
import { ApiService } from '../core/api/api.service';
import { Situation, SituationEnvironmentEnum, SituationStateType } from '../core/domain';

type SituationChangeEvent = {
  data: Situation;
  type: SituationChangeEventType;
};

type SituationChangeEventType = 'init' | 'switch' | 'update';

type EditMode = 'operational' | 'draft' | 'trainning' | 'exercise' | '';

@Injectable({
  providedIn: 'root',
})
export class DashboardSituationContextService {
  // data block
  private $data: BehaviorSubject<SituationChangeEvent> = new BehaviorSubject<SituationChangeEvent>({
    data: {},
    type: 'init',
  } as SituationChangeEvent);
  private data$ = this.$data.asObservable();

  private editMode: EditMode = 'operational';

  getEditMode() {
    return this.editMode;
  }

  readonly unsubscribe$ = new Subject();

  constructor(private apiService: ApiService, private clusterService: ClusterService) {
    this.onDataChange()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((situation) => {
        this.editMode = this.situationToEditMode(situation);
      });
  }

  unsubscribe(): void {
    this.unsubscribe$.next(null);
    this.unsubscribe$.complete();
  }

  private setData(data: Situation, type: SituationChangeEventType) {
    this.$data.next({ data, type });
  }

  getData(): Situation {
    return this.$data.getValue()?.data;
  }

  onDataChange() {
    return this.data$
      .pipe(
        filter((s) => !!s?.data?._id),
        map((e) => e.data),
      )
      .pipe(takeUntil(this.unsubscribe$));
  }

  onChange() {
    return this.data$.pipe(filter((s) => !!s?.data?._id)).pipe(takeUntil(this.unsubscribe$));
  }

  switchSituation(sit: Situation) {
    this.setData(sit, 'switch');
    this.apiService.situation.getContextOperationalSituation(sit._id, sit.Environment).pipe(take(1)).subscribe();
  }

  switchToDefault() {
    const situation = this.getData();
    const initCluster = this.clusterService.getInitialCluster();

    const environment = situation.Environment ?? initCluster ?? SituationEnvironmentEnum.Live;

    this.apiService.situation
      .getContextOperationalSituation(undefined, environment)
      .pipe(take(1))
      .subscribe((sit) => {
        this.setData(sit, 'switch');
      });
  }

  initSituation(sit: Situation) {
    this.setData(sit, 'init');
  }

  updateSituation(sit: Situation) {
    this.setData(sit, 'update');
  }

  private situationToEditMode(situation: Situation): EditMode {
    if (situation.State?.Type === SituationStateType.Draft) return 'draft';

    switch (situation.Environment) {
      case undefined:
      case SituationEnvironmentEnum.Live:
        return 'operational';
      case SituationEnvironmentEnum.Training:
        return 'trainning';
      case SituationEnvironmentEnum.Exercise:
        return 'exercise';
      default:
        console.error('Situation Environment is not valid');
        return '';
    }
  }

  isDraftSituation(): boolean {
    if (this.getData().State?.Type) return this.getData().State?.Type === SituationStateType.Draft;

    return false;
  }
}
