import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigParamsApiService } from 'src/app/core/api/endpoints/config-params.api.service';
import { ReplayApiService } from 'src/app/core/api/endpoints/replay.api.service';
import { DynamicProxyService } from '../utils/dynamic-proxy.service';
import { AdminApiService } from './endpoints/admin.api.service';
import { ApiKeyService } from './endpoints/api-key.service';
import { AuditApiService } from './endpoints/audit.api.service';
import { ChatApiService } from './endpoints/chat.api.service';
import { ContentApiService } from './endpoints/content.api.service';
import { CpaApiService } from './endpoints/cpa.api.service';
import { DataApiService } from './endpoints/data.service';
import { DirexLoudSpeakerService } from './endpoints/direx-loud-speaker.api.service';
import { FolderApiService } from './endpoints/folder.api.service';
import { GroupApiService } from './endpoints/group.api.service';
import { IconApiService } from './endpoints/icon.api.service';
import { LayerApiService } from './endpoints/layer.api.service';
import { LogApiService } from './endpoints/log.api.service';
import { MapObjectFilterApiService } from './endpoints/map-object-filter.api.service';
import { MessageBoardApiService } from './endpoints/messageboard.api.service';
import { MsaObjectApiService } from './endpoints/msaobject.api.service';
import { NotificationApiService } from './endpoints/notification.api.service';
import { OWSService } from './endpoints/ows.service';
import { SensorApiService } from './endpoints/sensor.api.service';
import { ShipDatabaseApiService } from './endpoints/ship-database.api.service';
import { SituationApiService } from './endpoints/situation.api.service';
import { TodoService } from './endpoints/todo.service';
import { TranslationApiService } from './endpoints/translation.api.service';
import { UsergroupApiService } from './endpoints/user-group.api.service';
import { ViewSiteApiService } from './endpoints/view-site.api.service';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  admin: AdminApiService;
  layers: LayerApiService;
  messageBoard: MessageBoardApiService;
  userGroup: UsergroupApiService;
  chat: ChatApiService;
  log: LogApiService;
  content: ContentApiService;
  situation: SituationApiService;
  folder: FolderApiService;
  shipDatabase: ShipDatabaseApiService;
  sensor: SensorApiService;
  audit: AuditApiService;
  group: GroupApiService;
  notification: NotificationApiService;
  msaObject: MsaObjectApiService;
  dataService: DataApiService;
  icon: IconApiService;
  cpa: CpaApiService;
  mapObjectFilter: MapObjectFilterApiService;
  viewSite: ViewSiteApiService;
  direxLoudSpeaker: DirexLoudSpeakerService;
  translation: TranslationApiService;
  todoService: TodoService;
  ows: OWSService;
  apiKey: ApiKeyService;
  replay: ReplayApiService;
  configParams: ConfigParamsApiService;

  constructor(private http: HttpClient, private dps: DynamicProxyService) {
    this.layers = new LayerApiService(this.http);
    this.messageBoard = new MessageBoardApiService(this.http);
    this.userGroup = new UsergroupApiService(this.http);
    this.chat = new ChatApiService(this.http);
    this.log = new LogApiService(this.http);
    this.content = new ContentApiService(this.http);
    this.situation = new SituationApiService(this.http);
    this.admin = new AdminApiService(this.http);
    this.folder = new FolderApiService(this.http);
    this.shipDatabase = new ShipDatabaseApiService(this.http);
    this.sensor = new SensorApiService(this.http);
    this.audit = new AuditApiService(this.http);
    this.group = new GroupApiService(this.http);
    this.notification = new NotificationApiService(this.http);
    this.msaObject = new MsaObjectApiService(this.http);
    this.dataService = new DataApiService(this.http);
    this.icon = new IconApiService(this.http);
    this.cpa = new CpaApiService(this.http);
    this.mapObjectFilter = new MapObjectFilterApiService(this.http);
    this.viewSite = new ViewSiteApiService(this.http);
    this.direxLoudSpeaker = new DirexLoudSpeakerService(this.http);
    this.translation = new TranslationApiService(this.http);
    this.ows = new OWSService(this.http, this.dps);
    this.apiKey = new ApiKeyService(this.http);
    this.replay = new ReplayApiService(this.http);
    this.configParams = new ConfigParamsApiService(this.http);

    this.todoService = new TodoService(this.http);
  }
}
