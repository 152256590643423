import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as Domain from '../../domain';

@Injectable()
export class ConfigParamsApiService {
  constructor(private http: HttpClient) {}

  private readonly api_path = '/light/api/config-params';

  public getConfigParamsByGroup(group: string): Observable<Domain.ConfigParams[]> {
    return this.http.get<Domain.ConfigParams[]>(`${this.api_path}`, { params: { group } });
  }

  public update(group: string, token: string, config: any): Observable<Domain.ConfigParams> {
    return this.http.get<Domain.ConfigParams>(`${this.api_path}`, { params: { group, token, config } });
  }
}
